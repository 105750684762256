<template>
  <div id="partnership-view">
    
  </div>
</template>

<script>
export default {
  name: 'Partnership',
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">

</style>